import React, { useEffect, useState } from 'react';
import ContainerComponent from './components/container.component';
import { NonCompatibleBrowser } from './components/non-compatible-browser';
import { CookiesProvider } from 'react-cookie';

export const App = () => {
  const [nonCompatibleBrowser, setNonCompatibleBrowser] = useState(false);

  useEffect(() => {
    if (window.nonCompatibleBrowser) {
      setNonCompatibleBrowser(window.nonCompatibleBrowser);
    }
  }, []);

  return nonCompatibleBrowser ? (
    <NonCompatibleBrowser />
  ) : (
    <CookiesProvider>
      <ContainerComponent />
    </CookiesProvider>
  );
};
