export const checkIfAuthenticationTokenIsValid = async (
  authInfo,
  token,
  encryptedDoctorCompanyId,
  encryptedSignature
) => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('RequestInfo', 'TestUser#TestPass###');

  const { doctorCompanyId, signature } = authInfo;
  const options = {
    headers,
    credentials: 'same-origin',
    method: 'post',
    body: JSON.stringify({
      DoctorCompanyId: doctorCompanyId,
      Token: token,
      Signature: signature,
      EncryptedSignature: encryptedSignature,
      EncryptedDoctorCompanyId: encryptedDoctorCompanyId,
    }),
  };
  try {
    const res = await fetch(
      `${process.env.REACT_APP_RXNT_DASHBOARD_API_URL}/dashboardservices/authentication/CheckIfAuthenticationTokenIsValid`,
      options
    );
    const tokenRes = await res.json();
    return tokenRes && tokenRes.ValidationStatus === 'Success';
  } catch (err) {
    console.log(
      '🚀 ~ file: container.component.js ~ line 811 ~ ContainerComponent ~ logout ~ err',
      err
    );
    return false;
  }
};

export const logoutUser = async (authInfo, fromApplication) => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('RequestInfo', 'TestUser#TestPass###');

  const { doctorCompanyId, token, signature } = authInfo;
  const options = {
    headers,
    credentials: 'same-origin',
    method: 'post',
    body: JSON.stringify({
      DoctorCompanyId: doctorCompanyId,
      Token: token,
      Signature: signature,
      FromApplication: fromApplication,
    }),
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_RXNT_DASHBOARD_API_URL}/dashboardservices/authentication/Logout`,
      options
    );
    await res.json();
  } catch (err) {
    /* eslint-disable-next-line no-empty */
  }
};

export const getWeavyConfig = async (authInfo, loginId, companyId) => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('RequestInfo', 'TestUser#TestPass###');

  const { doctorCompanyId, token, signature } = authInfo;
  const options = {
    headers,
    credentials: 'same-origin',
    method: 'post',
    body: JSON.stringify({
      DoctorCompanyId: doctorCompanyId,
      Token: token,
      Signature: signature,
      LoginId: loginId,
      CompanyId: companyId,
    }),
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_RXNT_MASTER_INDEX_API_URL}/rxntmasterindex/weavy/GetWeavyConfig/`,
      options
    );
    return await res.json();
  } catch (err) {
    /* eslint-disable-next-line no-empty */
  }
};

export const getWeavyUserToken = async (authInfo, username, directory, name) => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('RequestInfo', 'TestUser#TestPass###');

  const { doctorCompanyId, token, signature } = authInfo;
  const options = {
    headers,
    credentials: 'same-origin',
    method: 'post',
    body: JSON.stringify({
      DoctorCompanyId: doctorCompanyId,
      Token: token,
      Signature: signature,
      Username: username,
      Directory: directory,
      Name: name,
    }),
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_RXNT_MASTER_INDEX_API_URL}/rxntmasterindex/weavy/GetUserToken/`,
      options
    );
    const data = await res.json();
    return data.Token.toString();
  } catch (err) {
    console.log(err);
  }
};

export const getBannerAdPreferenceFromEHR = async (
  authInfo,
  externalLoginId,
  externalCompanyId
) => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('RequestInfo', 'TestUser#TestPass###');

  const { doctorCompanyId, token, signature } = authInfo;
  const options = {
    headers,
    credentials: 'same-origin',
    method: 'post',
    body: JSON.stringify({
      DoctorCompanyId: doctorCompanyId,
      Token: token,
      Signature: signature,
      ExternalLoginId: externalLoginId,
      ExternalCompanyId: externalCompanyId,
    }),
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_RXNT_DASHBOARD_API_URL}/dashboardservices/sgpromotion/GetBannerAdPreferenceFromEHR/`,
      options
    );
    return await res.json();
  } catch (err) {
    console.log('🚀 ~ file: service.js ~ line 153 ~ getBannerAdPreferenceFromEHR ~ err', err);
  }
};
