/* eslint react/prop-types: off */
import React from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';

const RxntLaunchDarklyProvider = ({ children, ldUser, clientId }) => {
  return (
    <LDProvider clientSideID={clientId} user={ldUser} reactOptions={{ useCamelCaseFlagKeys: true }}>
      {children}
    </LDProvider>
  );
};

export default RxntLaunchDarklyProvider;
