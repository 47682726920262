export const microFrontendApps = [
  {
    eventTrigger: 'dashboardSite',
    applicationPath: '/dashboard/dashboardsite',
  },
  {
    eventTrigger: 'enrollmentSite',
    applicationPath: '/dashboard/enrollmentsite',
  },
  {
    eventTrigger: 'corporateDashboardSite',
    applicationPath: '/dashboard/CorporateDashboardSite',
    default: true,
  },
];

export const headerEventTriggers = [
  {
    eventTrigger: 'loginUser',
  },
  {
    eventTrigger: 'autoLogoutUser',
  },
  {
    eventTrigger: 'resetUserInfo',
  },
  {
    eventTrigger: 'loadedFrontEndApplication',
  },
  {
    eventTrigger: 'initializeCorporateMenu',
  },
  {
    eventTrigger: 'updateUtilitiesMenu',
  },
  {
    eventTrigger: 'showLoading',
  },
  {
    eventTrigger: 'hideLoading',
  },
  {
    eventTrigger: 'showUtilitiesMenu',
  },
  {
    eventTrigger: 'hideUtilitiesMenu',
  },
  {
    eventTrigger: 'showHeader',
  },
  {
    eventTrigger: 'hideHeader',
  },
  {
    eventTrigger: 'showNotificationBanner',
  },
];

export const enrollmentStatusCodes = {
  draftAccount: 'DFTAC',
  eprAssigned: 'EPRAS',
  importFailed: 'IMFAL',
  importSuccess: 'IMSUC',
  verified: 'VERFD',
  pendingVerification: 'VFPND',
  addClientCompanyFailed: 'CCFAL',
};
export const utilitiesMenuItemCodes = {
  locationAndStaffManagement: 'LASMT',
  contactlessPatientEnrollment: 'CLPEN',
  manageTokens: 'MGTKN',
  epcsTokenQueue: 'ETKQU',
};
export const utilitiesMenuItems = [
  {
    Code: 'UPPWD',
    Name: 'Update Password',
    URL: '/changepassword',
    IsDefault: true,
    SortOrder: 1,
  },
  {
    Code: 'AUDLG',
    Name: 'Audit Log',
    URL: '/auditlog',
    SortOrder: 2,
  },
  {
    Code: 'PAYOP',
    Name: 'Payment Options',
    URL: '/paymentoptions',
    SortOrder: 3,
  },
  {
    Code: 'INVCE',
    Name: 'Invoices',
    URL: '/invoices',
    SortOrder: 4,
  },
  {
    Code: utilitiesMenuItemCodes.locationAndStaffManagement,
    Name: 'Location and Staff Management',
    URL: '/v2/locationAndStaffManagement',
    SortOrder: 5,
  },
  {
    Code: 'SNDFL',
    Name: 'Send File',
    URL: '/v2/send-file',
    SortOrder: 7,
  },
  {
    Code: 'UPLGO',
    Name: 'Upload Logo',
    URL: '/v2/uploadlogo',
    SortOrder: 8,
  },
  {
    Code: 'ORGZN',
    Name: 'Organization',
    URL: '/enrollment/view-organization',
    SortOrder: 9,
  },
  {
    Code: 'PRVDR',
    Name: 'Providers',
    URL: '/enrollment/provider-dashboard',
    SortOrder: 10,
  },
  {
    Code: 'SUBSN',
    Name: 'Subscriptions',
    URL: '/enrollment/subscriptions',
    SortOrder: 11,
  },
  {
    Code: utilitiesMenuItemCodes.manageTokens,
    Name: 'Manage Tokens',
    URL: '/enrollment/manage-tokens',
    SortOrder: 12,
  },
  {
    Code: utilitiesMenuItemCodes.epcsTokenQueue,
    Name: 'EPCS Token Queue',
    URL: '/v2/epcs-token-queue',
    SortOrder: 13,
  },
  {
    Code: 'CMPPR',
    Name: 'Company Preferences',
    URL: '/preferenceSetup',
    SortOrder: 14,
  },
  {
    Code: 'GRPRE',
    Name: 'Group Preferences',
    URL: '/v2/groupPreferences',
    SortOrder: 15,
  },
  {
    Code: 'CLPEN',
    Name: 'Contactless Patient Enrollment',
    URL: '/v2/contactlessPatientEnrollment',
    IsDefault: false,
    SortOrder: 16,
  },
  {
    Code: 'IFMMG',
    Name: 'Intake Form Management',
    URL: '/patientIntake',
    SortOrder: 17,
  },
  {
    Code: 'PMPCN',
    Name: 'PMP/PDMP Connectivity',
    URL: '/enrollment/pmp-connectivity',
    SortOrder: 18,
  },
  {
    Code: 'CNCCM',
    Name: 'Chief Complaints',
    URL: '/v2/chiefComplaints',
    IsDefault: false,
    SortOrder: 19,
  },
];

export const PracticeAdminRoleCode = 'DBPRA';
