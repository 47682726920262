import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './app';
import { unregister } from './registerServiceWorker';
import '@rxnt/icons';
import RxntLaunchDarklyProvider from './common/launch-darkly/launch-darkly.component';

/* eslint-disable-next-line react/no-deprecated */
ReactDOM.render(
  <RxntLaunchDarklyProvider
    clientId={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}
    ldUser={{
      kind: 'user',
      key: 'anonymous-user',
      doctorCompanyId: 0,
      doctorGroupId: 0,
      v2DoctorCompanyId: 0,
      anonymous: true,
    }}
  >
    <App />
  </RxntLaunchDarklyProvider>,
  document.getElementById('root')
);
unregister();
