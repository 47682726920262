import React from 'react';
import { useCookies } from 'react-cookie';

export const WindowNameManager = () => {
  const [cookies] = useCookies(['RxNTTokenNewParam3', 'RxNTLoginNew']);
  React.useEffect(() => {
    if (
      cookies &&
      (!cookies.RxNTTokenNewParam3 || !cookies.RxNTLoginNew || cookies.RxNTLoginNew === 'false') &&
      window.name &&
      (window.name.toLowerCase().includes('billing') ||
        window.name.toLowerCase().includes('scheduler') ||
        window.name.toLowerCase().includes('ehrerx'))
    ) {
      window.name = 'dashboard';
    }
  }, [cookies]);
  return null;
};
