/* eslint react/prop-types: off */
import React, { useMemo } from 'react';
import { RxntHeader } from '@rxnt/mui-components';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

export const RxntHeaderWrapper = (props) => {
  const {
    messagingDock,
    loginId,
    doctorCompanyId,
    v1DgId,
    showUtilitiesMenu,
    ...otherProps
  } = props;
  const { enableWeavyTasks, enablePatientMessaging } = useFlags();
  const ldClient = useLDClient();
  React.useEffect(() => {
    if (loginId && doctorCompanyId) {
      ldClient.identify(
        {
          kind: 'user',
          key: `dr_${loginId}`,
          doctorCompanyId: doctorCompanyId,
          doctorGroupId: v1DgId,
          v2DoctorCompanyId: doctorCompanyId,
          masterDoctorCompanyId: doctorCompanyId,
        },
        null,
        (err) => {
          console.log('launch darkly identify err', err);
        }
      );
    }
  }, [loginId, doctorCompanyId, v1DgId, ldClient]);

  const authorizedMenuItems = useMemo(() => {
    if (!showUtilitiesMenu || !otherProps.utilitiesMenu) return undefined;

    var menuItems = [...otherProps.utilitiesMenu.menuItems];

    return menuItems;
  }, [otherProps.utilitiesMenu, showUtilitiesMenu]);

  return (
    <RxntHeader
      {...otherProps}
      utilitiesMenu={
        showUtilitiesMenu
          ? { ...otherProps.utilitiesMenu, menuItems: authorizedMenuItems }
          : undefined
      }
      messagingDock={
        messagingDock
          ? {
              ...messagingDock,
              isTaskBoardEnabled: messagingDock.isWeavyEnabled && enableWeavyTasks,
              isCompanyWeavyPatientEnabled:
                messagingDock.isCompanyWeavyPatientEnabled && enablePatientMessaging,
            }
          : messagingDock
      }
    />
  );
};
