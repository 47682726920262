import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: '12px',
    color: '#003B5c',
  },
  header: {
    color: '#003B5c',
    textAlign: 'center',
    height: 30,
    fontFamily: 'Work Sans',
    fontSize: 20,
    lineHeight: 1.5,
  },
  sep: {
    margin: 'auto',
    width: '64px',
    height: '2px',
    backgroundColor: 'rgb(255, 127, 65)',
  },
  subHeader: {
    color: '#003B5c',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Muli',
    lineHeight: 1.5,
    letterSpacing: 0.25,
  },
  img: {
    display: 'flex',
    margin: 'auto',
    width: 114,
    height: 64,
  },
  customDialogRoot: {
    '& [class^="MuiBackdrop-root"]': {
      backgroundColor: 'rgb(0,35,55,0.65)',
    },
  },
});

export const NonCompatibleBrowser = () => {
  const classes = useStyles();
  return (
    <div style={{ textAlign: 'center' }}>
      <Dialog
        aria-labelledby='customized-dialog-title'
        open={true}
        classes={{ root: classes.customDialogRoot }}
      >
        <DialogTitle>
          <div className={classes.header}>Unsupported browser</div>
          <div className={classes.sep} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.subHeader}>
            We noticed that your team is accessing this site via an unsupported browser. Our
            supported browsers are Google Chrome (recommended), Microsoft Edge, and Mozilla Firefox.
            Please download Google Chrome to continue.
          </div>
          <a href='https://www.google.com/chrome/' target='_blank' rel='noopener noreferrer'>
            <img
              className={classes.img}
              src='https://www.google.com/chrome/static/images/chrome-logo.svg'
              alt='chrome'
            />
            <div className={classes.subHeader}>Google Chrome</div>
          </a>
        </DialogContent>
      </Dialog>
    </div>
  );
};
